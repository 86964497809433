import loadable from "@loadable/component";
import React from "react";

const ResourceListProxy = loadable(() =>import("../resources/resource-list"));

const ResourceList = ({
	data,
	share,
	featuredCategory,
	resourceFeedbackData,
}) => {
	return (
		<ResourceListProxy
			data={data}
			share={share}
			featuredCategory={featuredCategory}
			resourceFeedbackData={resourceFeedbackData}
		/>
	);
};

export default ResourceList;
