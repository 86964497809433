import loadable from "@loadable/component";
import React from "react";

const ResourceCategoriesProxy = loadable(() =>import("../resources/resource-categories"));

const ResourceCategories = ({ data, featuredCategory }) => {
	return (
		<ResourceCategoriesProxy data={data} featuredCategory={featuredCategory} />
	);
};

export default ResourceCategories;
