import loadable from "@loadable/component";
import React from "react";

const ResourceCarouselProxy = loadable(() => import("../resources/resource-carousel"));

const ResourceCarousel = ({
	data,
	share,
	featuredCategory,
	resourceFeedbackData,
}) => {
	return (
		<ResourceCarouselProxy
			data={data}
			share={share}
			featuredCategory={featuredCategory}
			resourceFeedbackData={resourceFeedbackData}
		/>
	);
};

export default ResourceCarousel;
