import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { graphql } from "gatsby";
import tw from "twin.macro";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
// import Header from "../components/header";
import Header from "../components/dynamic-components/header";
// import BottomNavigation from "../components/bottom-navigation";
import BottomNavigation from "../components/dynamic-components/bottom-navigation";
// import Footer from "../components/footer";
import Footer from "../components/dynamic-components/footer";
// import ResourceCategories from "../components/resources/resource-categories";
import ResourceCategories from "../components/dynamic-components/resource-categories";
// import ResourceList from "../components/resources/resource-list";
import ResourceList from "../components/dynamic-components/resource-list";
// import GeneralFeedback from "../components/bottom-navigation/general-feedback";
import GeneralFeedback from "../components/dynamic-components/general-feedback";
import { TransitionState } from "gatsby-plugin-transition-link";
import { motion } from "framer-motion";
// import ResourceCarousel from "../components/resources/resource-carousel";
import ResourceCarousel from "../components/dynamic-components/resource-carousel";
import { Helmet } from "react-helmet";
import { ThemeContext } from "../components/themes/theme-context";
import { useIntl } from "gatsby-plugin-intl";
import SiteMetadata from "../components/site-metadata";

const InnerContainer = tw.div`max-w-screen-xl mx-auto`;

const StyledSection = tw.section`flex flex-col min-h-screen items-center px-4`;

const StyledSubheadline = tw.h1`font-normal text-lg font-serif leading-serif-lg px-8 lg:text-2-1/2xl lg:leading-serif-2-1/2xl lg:mb-8 text-center`;

const Resources = ({ pageContext, data }) => {
	const intl = useIntl();
	const { theme } = useContext(ThemeContext);

	const Bold = ({ children }) => <strong>{children}</strong>;
	const Italic = ({ children }) => (
		<span css={theme.textColor}>{children}</span>
	);
	const Subheadline = ({ children }) => (
		<StyledSubheadline>{children}</StyledSubheadline>
	);

	const options = {
		renderMark: {
			[MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
			[MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
		},
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<Subheadline>{children}</Subheadline>
			),
		},
	};

	const content = data.allContentfulResourcesPage.nodes.filter((item) => {
		return item.contentful_id === pageContext.data.contentful_id;
	})[0];

	const featuredCategory = data.allContentfulResourceCategory.nodes.filter(
		(item) => item.title === "Featured"
	);

	const generalFeedbackData = data.allContentfulFeedbackComponent.nodes.filter(
		(item) => item.title === "General Feedback"
	)[0];

	const resourceFeedbackData = data.allContentfulFeedbackComponent.nodes.filter(
		(item) => item.title === "Resource Feedback"
	)[0];

	// console.log(pageContext.data.slug);

	const viewType =
		pageContext.data.slug === "i-need-help" ? "carousel" : "list";

	const fadeAnimation = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
	};

	const selectedCategory = useSelector((state) =>
		state.resources.selectedCategory
			? state.resources.selectedCategory
			: featuredCategory[0]
	);

	const subheadline =
		viewType === "list"
			? selectedCategory.listViewSubheadline
			: selectedCategory.cardViewSubheadline;

	return (
		<>
			<Helmet
				title={content.titleBarText}
				htmlAttributes={{
					title: `${content.titleBarText}`,
					lang: `${intl.locale}`,
				}}
			/>
			<SiteMetadata data={data.allContentfulSiteMetadata} />
			<Header data={data.allContentfulHeader} pageTitle={content.headerText} />
			<InnerContainer>
				<StyledSection
					css={[
						viewType === "list" && tw`mt-32 lg:mt-48`,
						viewType !== "list" && tw`justify-center lg:mt-5`,
						viewType === "list" &&
							`
						@media (max-height: 600px) {
							margin-top: 2rem;
						}
						`,
						viewType !== "list" &&
							`
						@media (max-height: 600px) {
							justify-content: flex-start;
						}
						`,
					]}
				>
					<TransitionState>
						{({ mount }) => {
							return (
								<motion.div
									initial="hidden"
									animate={mount ? "visible" : ""}
									variants={fadeAnimation}
									transition={{ duration: 0.8 }}
								>
									{renderRichText(subheadline, options)}
									<ResourceCategories
										data={data.allContentfulResourceCategory}
										featuredCategory={featuredCategory}
									/>
								</motion.div>
							);
						}}
					</TransitionState>
					{viewType === "list" ? (
						<ResourceList
							data={data.allContentfulResource}
							share={data.allContentfulShare}
							featuredCategory={featuredCategory}
							resourceFeedbackData={resourceFeedbackData}
						/>
					) : (
						<ResourceCarousel
							data={data.allContentfulResource}
							share={data.allContentfulShare}
							featuredCategory={featuredCategory}
							resourceFeedbackData={resourceFeedbackData}
						/>
					)}
				</StyledSection>
			</InnerContainer>
			<Footer data={data.allContentfulFooter} />
			<BottomNavigation
				data={data.allContentfulBottomNavigation}
				languageData={data.allContentfulLanguageNavigation}
			/>
			<GeneralFeedback data={generalFeedbackData} />
		</>
	);
};

export const query = graphql`
	query ResourcePageInternalQuery($locale: String!) {
		allContentfulResourcesPage(filter: { node_locale: { eq: $locale } }) {
			nodes {
				id
				contentful_id
				headerText
				titleBarText
				slug
				title
				subheadline {
					raw
				}
			}
		}
		allContentfulResourceCategory(
			filter: { node_locale: { eq: $locale } }
			sort: { fields: menuOrder }
		) {
			nodes {
				...resourceCategoriesFragment
			}
		}
		allContentfulResource(filter: { node_locale: { eq: $locale } }) {
			nodes {
				...resource
			}
		}
		allContentfulFeedbackComponent(filter: { node_locale: { eq: $locale } }) {
			nodes {
				...feedbackComponent
			}
		}
		allContentfulFooter(filter: { node_locale: { eq: $locale } }) {
			nodes {
				...footer
			}
		}
		allContentfulHeader(filter: { node_locale: { eq: $locale } }) {
			nodes {
				...header
			}
		}
		allContentfulBottomNavigation(filter: { node_locale: { eq: $locale } }) {
			nodes {
				...bottomNavigation
			}
		}
		allContentfulLanguageNavigation(filter: { node_locale: { eq: $locale } }) {
			nodes {
				...languageNavigation
			}
		}
		allContentfulShare(filter: { node_locale: { eq: $locale } }) {
			nodes {
				...share
			}
		}
		allContentfulSiteMetadata(filter: { node_locale: { eq: $locale } }) {
			nodes {
				...siteMetadata
			}
		}
	}
`;

export default Resources;
